import {actionMap, typeTextMap, capitalize, getPriceRangeString, getSizeRangeString, getBedroomType} from "../SeoContentUtils/commonUtils";


/** Generates a meta description for property pages based on SEO parameters. **/

export const generateMetaDescription = (seoParams) => {
    const { pType, areas, bedrooms, type, minsize, maxsize, price } = seoParams;
    const action = actionMap[pType] || "";
    const area = areas ? capitalize(areas) : 'Dubai';
    const propertyType = type ? capitalize(type) : '';
  
    let metaDescription = `Looking for Properties ${action} in ${area}? Reach out to The Pearl Gates real estate agents in ${area} to find your perfect match.`;
  
    // [price-range]+[properties-type]+[bedroom]+[size]
    if ((price && (price.min !== '' || price.max !== '')) && type && (bedrooms !== "" && bedrooms >= 0) && (minsize !== '' || maxsize !== '')) {
        const priceRange = getPriceRangeString(price);
        const bedroomType = getBedroomType(bedrooms);
        const sizeRange = getSizeRangeString(minsize, maxsize);
        metaDescription = `Explore our newest selection of ${bedroomType} ${propertyType} ${action} in ${area} ${priceRange} and ${sizeRange}. Contact The Pearl Gates today to discover your perfect property.`;
    } 
  
    // [price-range]+[Properties-type]+[bedroom]
    else if ((price && (price.min !== '' || price.max !== '')) && type && (bedrooms !== "" && bedrooms >= 0)) {
        const priceRange = getPriceRangeString(price);
        const bedroomType = getBedroomType(bedrooms);
        metaDescription = `In Search of ${bedroomType} ${propertyType} ${action} in ${area} ${priceRange}? Get in touch with The Pearl Gates to arrange a viewing and secure your perfect property.`;
    }
  
    // [properties-type]+[size] 
    else if (type && (minsize !== '' || maxsize !== '')) {
        const sizeRange = getSizeRangeString(minsize, maxsize);
        metaDescription = `Browse the latest collection of ${propertyType} ${action} in ${area} ${sizeRange}. Contact The Pearl Gates Dubai real estate agents now and let us assist you in finding your perfect property.`;
    }
    
    // [properties-type]+[bedroom] 
    else if (type && (bedrooms !== "" && bedrooms >= 0)) {
        const bedroomType = getBedroomType(bedrooms);
        metaDescription = `Ready to find your ideal ${bedroomType} ${propertyType} ${action} in ${area}? Contact The Pearl Gates and let us assist you in your property search.`;
    }
  
    // [price-range] + [size]
    else if ((price && (price.min !== '' || price.max !== '')) && (minsize !== '' || maxsize !== '')) {
        const priceRange = getPriceRangeString(price);
        const sizeRange = getSizeRangeString(minsize, maxsize);
        metaDescription = `Searching for Properties ${action} in ${area} ${priceRange} and ${sizeRange}? Contact The Pearl Gates, premium real estate agents in ${area}, to book a viewing.`;
    }
  
    // [price-range]+[bedroom] 
    else if ((price && (price.min !== '' || price.max !== '')) && (bedrooms !== "" && bedrooms >= 0)) {
        const priceRange = getPriceRangeString(price);
        const bedroomType = getBedroomType(bedrooms);
        metaDescription = `Discover the wide range of ${bedroomType} Properties ${action} in ${area} ${priceRange}. Contact The Pearl Gates Dubai real estate agents to schedule a viewing.`;
    }
  
    // [price-range]+[properties-type]
    else if ((price && (price.min !== '' || price.max !== '')) && type) {
        const priceRange = getPriceRangeString(price);
        metaDescription = `Find the perfect ${propertyType} ${action} in ${area} ${priceRange} with the assistance of The Pearl Gates. Begin your journey to finding your dream home today!`;
    }
  
    // [size]
    else if (minsize !== '' || maxsize !== '') {
        const sizeRange = getSizeRangeString(minsize, maxsize);
        metaDescription = `Are you searching for Properties ${action} in ${area} ${sizeRange}? Get in touch with The Pearl Gates real estate agents in ${area} to book a viewing of this property.`;
    }
  
    // [price-range] 
    else if (price && (price.min !== '' || price.max !== '')) {
        const priceRange = getPriceRangeString(price);
        metaDescription = `Browse through an exclusive selection of Properties ${action} in ${area} ${priceRange} with the help of The Pearl Gates. Explore our diverse range of listings today!`;
    }
  
    // [bedroom] 
    else if (bedrooms !== "" && bedrooms >= 0) {
        const bedroomType = getBedroomType(bedrooms);
        metaDescription = `Explore the latest collection of ${bedroomType} Properties ${action} in ${area} here. Contact The Pearl Gates to kickstart your search today!`;
    }
  
    // [properties-type]
    else if (type) {
        metaDescription = `Discover an array of ${propertyType} ${action} in ${area} with the assistance of The Pearl Gates real estate agents in Dubai. Get in touch with us to find your dream home today!`;
    }
  
    return metaDescription;
  };
  
  /** Generates introductory copy for property pages based on SEO parameters. **/

  export const generateIntroCopy = (seoParams) => {
    const { pType, areas, bedrooms, type, minsize, maxsize, price } = seoParams;
    const action = actionMap[pType] || "";
    const area = areas ? capitalize(areas) : 'Dubai';
    const saleTypeText = typeTextMap[pType] || "";  
    const propertyType = type ? capitalize(type) : '';
  
    let introCopy = ""; // Initialize introCopy to empty string
  
    // [price-range]+[properties-type]+[bedroom]+[size]
    if ((price && (price.min !== '' || price.max !== '')) && type && (bedrooms !== "" && bedrooms >= 0) && (minsize !== '' || maxsize !== '')) {
      const priceRange = getPriceRangeString(price);
      const bedroomType = getBedroomType(bedrooms);
      const sizeRange = getSizeRangeString(minsize, maxsize);
      introCopy = ` ${bedroomType} ${propertyType} ${action} in ${area} ${priceRange} and ${sizeRange}`;
    } 
  
    // [price-range]+[Properties-type]+[bedroom]
    else if ((price && (price.min !== '' || price.max !== '')) && type && (bedrooms !== "" && bedrooms >= 0)) {
      const priceRange = getPriceRangeString(price);
      const bedroomType = getBedroomType(bedrooms);
      introCopy = ` ${bedroomType} ${propertyType} ${action} in ${area} ${priceRange}`;
    }
  
    // [properties-type]+[size] 
    else if (type && (minsize !== '' || maxsize !== '')) {
      const sizeRange = getSizeRangeString(minsize, maxsize);
      introCopy = ` ${propertyType} ${action} in ${area} ${sizeRange}`;
    }
  
    // [properties-type]+[bedroom] 
    else if (type && (bedrooms !== "" && bedrooms >= 0)) {
      const bedroomType = getBedroomType(bedrooms);
      introCopy = ` ${bedroomType} ${propertyType} ${action} in ${area}`;
    }
  
    // [price-range] + [size]
    else if ((price && (price.min !== '' || price.max !== '')) && (minsize !== '' || maxsize !== '')) {
      const priceRange = getPriceRangeString(price);
      const sizeRange = getSizeRangeString(minsize, maxsize);    
      introCopy = ` Properties ${action} in ${area} ${priceRange} and ${sizeRange}`;
    }
  
    // [price-range]+[bedroom] 
    else if ((price && (price.min !== '' || price.max !== '')) && (bedrooms !== "" && bedrooms >= 0)) {
      const priceRange = getPriceRangeString(price);
      const bedroomType = getBedroomType(bedrooms);
      introCopy = ` ${bedroomType} Properties ${action} in ${area} ${priceRange}`;
    }
  
    // [price-range]+[properties-type]
    else if ((price && (price.min !== '' || price.max !== '')) && type) {
      const priceRange = getPriceRangeString(price);
      introCopy = ` ${propertyType} ${action} in ${area} ${priceRange}`;
    }
  
    // [size]
    else if (minsize !== '' || maxsize !== '') {
      const sizeRange = getSizeRangeString(minsize, maxsize);
      introCopy = ` Properties ${action} in ${area} ${sizeRange}`;
    }
  
    // [price-range] 
    else if (price && (price.min !== '' || price.max !== '')) {
      const priceRange = getPriceRangeString(price); 
      introCopy = ` Properties ${action} in ${area} ${priceRange}`;    
    }
  
    // [bedroom] 
    else if (bedrooms !== "" && bedrooms >= 0) {
      const bedroomType = getBedroomType(bedrooms);
      introCopy = ` ${bedroomType} Properties ${action} in ${area}`;
    }
  
    // [properties-type]
    else if (type) {
      introCopy = ` ${propertyType} ${action} in ${area}`;
    }
    //default
    else {
      introCopy = ` Properties ${action} in ${area}`;
    }
  
    introCopy += ` with The Pearl Gates. Our expert <a href="${process.env.GATSBY_SITE_URL}">real estate agents in Dubai</a> are ready to assist you. Use the above filter to refine your search results, or for more information about ${saleTypeText} property in ${area}, contact The Pearl Gates real estate agents in Dubai`;
  
    // Add the common introductory phrase if introCopy is not empty
    if (introCopy !== "") {
      introCopy = `Explore our latest collection of ${introCopy}`;
    }
  
    return introCopy;
};